import React, { useEffect, useState } from 'react'
import PageContainer from '../../utils/PageContainer'
import styles from './EnrollmentContainer.module.css'
import WebRtc from './WebRtc'
import Button from '../../utils/Button'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { api } from '../../../App'
import { BASE_PARAMS } from '../../../constants/api'

import Task from './Task'
import Header from '../../utils/Header'
import { fetchApplicantPsycho, saveApplicantPsycho } from '../../../model/common/psycho/actions'
import ScoreModal from './ScoreModal'
import LessonTimer from './LessonTimer'
import { useHistory } from 'react-router'
import { Progress } from 'shards-react'
import ApplicantInfo from './ApplicantInfo'
import Spinner from '../../utils/Spinner'

const mapStateToProps = ({ applicantSchedule, auth, GOUInfo, psycho }) => ({
  selectedItem: applicantSchedule.selectedItem,
  authData: auth.data,
  psycho,
  GOUInfo,
})

const mapDispatchToProps = {
  fetchApplicantPsycho,
  saveApplicantPsycho,
}

const EnrollmentContainer = ({ selectedItem, authData, GOUInfo, psycho, fetchApplicantPsycho, saveApplicantPsycho }) => {
  let history = useHistory()

  if (!selectedItem) {
    history.push('/')
  }

  const connect_code = !!selectedItem && authData.base + '_' + selectedItem.schedule_id + '_' + selectedItem.access_code

  useEffect(() => {
    if (selectedItem) {
      fetchApplicantPsycho(selectedItem.applicant_id)
    }
  }, [fetchApplicantPsycho])

  const [taskList, setTaskList] = useState(null)
  const [count, setCount] = useState(1)
  const [question, setQuestion] = useState(null)
  const [loader, setLoader] = useState(false)
  const [isPsyModalOpen, setPsyModalOpen] = useState(false)
  const [userAnswer, setUserAnswer] = useState(null)
  const [ticketSelected, setTicketSelected] = useState(0)

  useEffect(() => {
    if (ticketSelected) {
      fetchTask()
    }
  }, [ticketSelected])

  useEffect(() => {
    return closeTest
  }, [])

  const nextTask = () => {
    count < taskList.length ? setCount(count + 1) : console.log('err')
  }

  const prevTask = () => {
    count >= 1 ? setCount(count - 1) : console.log('err')
  }

  const setTaskNumber = number => {
    setCount(+number)
  }

  const closeTest = () => {
    const params = {
      ...BASE_PARAMS,
      action: 'applicant.closetest',
      base: authData.base,
      login: authData.login,
      pass: authData.pass,
      applicant: authData.applicant,
      schedule: selectedItem && selectedItem.schedule_id,
      status: 2,
    }

    api.get('/', { params })
  }

  const fetchTask = () => {
    const params = {
      ...BASE_PARAMS,
      action: 'applicant.ticket',
      base: authData.base,
      login: authData.login,
      pass: authData.pass,
      schedule: selectedItem && selectedItem.schedule_id,
    }

    api
      .get('/', { params })
      .then(response => {
        setTaskList(response.data.Answer.Data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAnswer = idquestion => {
    setLoader(true)
    const params = {
      ...BASE_PARAMS,
      action: 'applicant.questionInfo',
      base: authData.base,
      login: authData.login,
      pass: authData.pass,
      question: idquestion,
      applicant: authData.applicant,
    }
    api
      .get('/', { params })
      .then(response => {
        setQuestion(response.data.Answer.Data[0])
        setLoader(false)
      })
      .catch(err => {
        console.log(err)
        setLoader(false)
      })
  }

  const requestApplicantAnswer = question => {
    const params = {
      ...BASE_PARAMS,
      action: 'applicant.viewanswer',
      base: authData.base,
      login: authData.login,
      pass: authData.pass,
      schedule: selectedItem.schedule_id,
      question,
    }

    api
      .get('/', { params })
      .then(response => {
        setUserAnswer(response.data.Answer.Data)
      })
      .catch(err => console.log(err))
  }

  const onSavePsycho = (callback, inputs) => {
    if (selectedItem) {
      saveApplicantPsycho(selectedItem.applicant_id, callback, inputs)
    }
  }

  if (!selectedItem) return <Redirect to={'/'} />

  return (
    <PageContainer>
      {isPsyModalOpen && <ScoreModal data={psycho} setOpen={setPsyModalOpen} saveData={onSavePsycho} />}
      <div className={styles.container}>
        <div className={styles.left}>
          <Header GOUName={GOUInfo.name} logo={GOUInfo.logo} />
          <LessonTimer />
          <WebRtc
            connect_code={connect_code}
            setTicketSelected={setTicketSelected}
            requestApplicantAnswer={requestApplicantAnswer}
            setTaskNumber={setTaskNumber}
            selectedItem={selectedItem}
            authData={authData}
          />
        </div>

        <div className={styles.right}>
          <ApplicantInfo selectedItem={selectedItem} authData={authData} />

          <div className={styles.task}>
            {ticketSelected ? (
              <div className={styles.task_wrapper}>
                {taskList &&
                  taskList.map(
                    item =>
                      item.q_order == count && (
                        <Task
                          data={item}
                          getAnswer={getAnswer}
                          ticketNumber={ticketSelected}
                          currentTast={count}
                          question={question}
                          count={count}
                          requestApplicantAnswer={requestApplicantAnswer}
                          userAnswer={userAnswer}
                          loader={loader}
                        />
                      )
                  )}
                {taskList && (
                  <div>
                    <div className={styles.progressbar}>
                      <Progress theme={'primary'} value={(100 / taskList.length) * count} />
                    </div>
                    <div className={styles.progressInfo}>
                      {count > 1 && (
                        <div className={styles.progressInfo_button} onClick={() => prevTask()}>
                          Назад
                        </div>
                      )}
                      <div className={styles.progressInfo_current}>
                        Задание {count} / {taskList.length}
                      </div>
                      {count < taskList.length && (
                        <div className={styles.progressInfo_button} onClick={() => nextTask()}>
                          Вперёд
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.ticket_on_select}>
                <Spinner color={'#FFFFFF'} />
                Претендент еще не выбрал билет
              </div>
            )}
          </div>

          <div className={styles.buttons}>
            <Button
              label={`Выставить оценку: ${psycho[0] ? psycho[0].psycho_grade : ''} (редактировать)`}
              onClick={() => setPsyModalOpen(true)}
              colorType={'dark-blue'}
            />
            <div className={styles.buttonsControl}>
              <Link to='/'>
                <Button label={'Возврат к списку детей'} colorType={'dark-blue'} />
              </Link>
              <Link to='/'>
                <Button label={'Завершить собеседование'} colorType={'red'} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentContainer)
