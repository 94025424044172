import {
    AUTH_REQUEST,
    ERROR,
    RESET_AUTH_DATA,
    START,
    SUCCESS
} from "../../../../constants/action-types";
import {api} from "../../../../App";


export const authRequestStart = () => ({
    type: `${AUTH_REQUEST}${START}`
});

export const authRequestSuccess = (data) => ({
    type: `${AUTH_REQUEST}${SUCCESS}`,
    payload: data
});

export const authRequestError = (data) => ({
    type: `${AUTH_REQUEST}${ERROR}`,
    payload: data
});

export const resetAuthData = () => ({
    type: RESET_AUTH_DATA
});


export const resetAuth = () => {
    return dispatch => {
        dispatch(resetAuthData());
        localStorage.removeItem('base');
        localStorage.removeItem('MID');
        localStorage.removeItem('Type');
        localStorage.removeItem('login');
        localStorage.removeItem('pass');
    }
};

export const startAuth = ({login, pass, base}) => {

    const params = {
        action: "comitet.check",
        type: "teacher",
        format: "json",
        base,
        login,
        pass
    };

    return dispatch => {
        dispatch(authRequestStart());

        api.get('/', {params})
            .then(res => {
                const data = {
                    ...res.data.Answer.Data_,
                    base,
                    login,
                    pass
                };

                dispatch(authRequestSuccess(data));

                for (let key in data) {
                    localStorage.setItem(key, data[key]);
                }
            })
            .catch(err => {
                dispatch(authRequestError(err.response.data.Answer.Message));
            });
    }
};
