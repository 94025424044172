import io from 'socket.io-client'
import * as RTCMultiConnection from 'rtcmulticonnection/dist/RTCMultiConnection'

import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './video.module.css'

import mic_mute from '../../../../images/icons/mute.svg'
import mic_active from '../../../../images/icons/microphone.svg'
import screen_share from '../../../../images/icons/share.svg'
import { createNotification } from '../../../utils/Notification'
import { createUUID } from '../../../../helpers/createUUID'
import StatePlug from './StatePlug'
import { streamRecorder } from './streamRecorder'

let localStream
let serverConnection
let bytesPrev = 0
let timestampPrev = 0

const WebRtc = ({ connect_code, requestApplicantAnswer, setTaskNumber, selectedItem, setTicketSelected }) => {
  window.io = io

  const SCREEN_SHARING_CONNECT_CODE = connect_code + '_screen'
  const SCREEN_SHARING_SOCKET_URL = 'https://webrtc.dnevnik-lms.ru:9001/'
  const SERVER_CONNECTION_SOCKET_URL = 'wss://webrtc.dnevnik-lms.ru'
  const REMOTE_VIDEO_TYPES = {
    NONE: 'none',
    WEBCAM: 'webcam',
    SCREEN: 'screen',
  }

  let remoteVideo
  let remoteVideoBg
  let localVideo
  let peerConnection
  let uuid
  let remoteVideoRecorder
  let localVideoRecorder
  let screenSharingJoinInterval

  let peerConnectionConfig = {
    iceServers: [
      { urls: 'stun:stun.edumil.ru:5349' },
      {
        urls: 'turn:turn.edumil.ru:5349?transport=tcp',
        credential: 'ceasar',
        username: 'edumil',
      },
      {
        urls: 'turn:turn.edumil.ru:5349?transport=udp',
        credential: 'ceasar',
        username: 'edumil',
      },
    ],
  }

  const [micSound, setMicSound] = useState(true)
  const [remoteVideoType, setRemoteVideoType] = useState(REMOTE_VIDEO_TYPES.NONE)
  const [completionScreen, setCompletionScreen] = useState(false)
  const [remoteScreenRecording, setRemoteScreenRecording] = useState(false)
  const [bitrate, setBitrate] = useState(0)

  let screenSharingConnection = useMemo(() => {
    const con = new RTCMultiConnection()
    con.socketURL = SCREEN_SHARING_SOCKET_URL
    con.socketMessageEvent = 'screen-sharing-demo'
    con.session = {
      screen: true,
      oneway: true,
    }
    con.enableLogs = false
    con.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: true,
    }
    con.iceServers = peerConnectionConfig.iceServers

    con.onstream = e => {
      console.log(e)
      document.getElementById('remoteScreen').srcObject = e.stream
      setRemoteScreenRecording(true)
      clearInterval(screenSharingJoinInterval)
    }

    con.onstreamended = e => {
      console.log('onstreamended')
      console.log(e)
      setRemoteVideoType(REMOTE_VIDEO_TYPES.WEBCAM)
      setRemoteScreenRecording(false)
    }

    return con
  }, [])

  const requestScreenSharing = () => {
    console.log(serverConnection)
    if (serverConnection) {
      serverConnection.send(
        JSON.stringify({
          requestScreenSharing: true,
          uuid: uuid,
          room: connect_code,
        })
      )
    }
  }

  useEffect(() => {
    if (!remoteScreenRecording) {
      screenSharingJoinInterval = setInterval(() => {
        console.log(screenSharingJoinInterval)
        console.log(screenSharingConnection)
        screenSharingConnection.join(SCREEN_SHARING_CONNECT_CODE)
      }, 4000)
    }
  }, [remoteScreenRecording])

  function pageReady() {
    uuid = createUUID()

    localVideo = document.getElementById('localVideo')
    remoteVideo = document.getElementById('remoteVideo')
    remoteVideoBg = document.getElementById('remoteVideoBg')

    serverConnection = new WebSocket(SERVER_CONNECTION_SOCKET_URL)
    serverConnection.onmessage = gotMessageFromServer

    serverConnection.onclose = e => {
      console.log(e.code)
      setTimeout(() => {
        pageReady()
      }, 1000)
    }

    let constraints = {
      video: true,
      audio: true,
    }

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints).then(getUserMediaSuccess).catch(errorHandler)
    } else {
      alert('Your browser does not support getUserMedia API')
    }
  }

  function getUserMediaSuccess(stream) {
    localStream = stream
    localVideo.srcObject = stream
  }

  function start(isCaller) {
    if (!peerConnection) {
      peerConnection = new RTCPeerConnection(peerConnectionConfig)
    }

    peerConnection.onicecandidate = gotIceCandidate
    peerConnection.ontrack = gotRemoteStream
    peerConnection.addStream(localStream)
    peerConnection.onconnectionstatechange = function (event) {
      switch (peerConnection.connectionState) {
        case 'connected':
          remoteVideoRecorder = streamRecorder()
          localVideoRecorder = streamRecorder()
          remoteVideoRecorder.start(remoteVideo.srcObject)
          localVideoRecorder.start(localVideo.srcObject)
          break
        case 'disconnected':
          console.log('disconnected')
          peerConnection.disconnectBackoff = 1
          peerConnection.restartIce()
          let timeout = setTimeout(() => {
            if (peerConnection.connectionState === 'disconnected') {
              closeConnection()
            }
            clearTimeout(timeout)
          }, 10000)
          break
        case 'failed':
          closeConnection()
          setCompletionScreen(true)
          break
        case 'closed':
          break
      }
    }

    if (isCaller) {
      peerConnection.createOffer().then(createdDescription).catch(errorHandler)
    }
  }

  function closeConnection() {
    if (!peerConnection) {
      return
    }
    if (peerConnection.connectionState === 'closed') {
      return
    }

    peerConnection.close()
    serverConnection.close()
    localStream.getTracks().forEach(track => track.stop())

    if (remoteVideoRecorder) {
      remoteVideoRecorder.stopRecording()
    }
    if (localVideoRecorder) {
      localVideoRecorder.stopRecording()
    }

    setTimeout(() => {
      const name = selectedItem.Child_FirstName
      const lastName = selectedItem.Child_LastName
      const midName = selectedItem.Child_MidName

      if (remoteVideoRecorder) {
        remoteVideoRecorder.downloadStream(`${connect_code}_1_${lastName}_${name}_${midName}`)
      }
      if (localVideoRecorder) {
        localVideoRecorder.downloadStream(`${connect_code}_2_${lastName}_${name}_${midName}`)
      }
    }, 300)
  }

  const join = froom => {
    if (serverConnection.readyState === 1) {
      serverConnection.send(JSON.stringify({ join: froom }))
    }
  }

  function gotMessageFromServer(message) {
    if (!peerConnection) start(false)
    let signal = JSON.parse(message.data)

    if (signal.answer) {
      requestApplicantAnswer(signal.answer)
      setTaskNumber(signal.count)
      return createNotification('success', 'Получен ответ от кандидата')
    }

    if (signal.ticket) {
      setTicketSelected(signal.ticket)
    }

    // Ignore messages from ourself
    if (signal.uuid == uuid) return

    if (signal.sdp) {
      peerConnection
        .setRemoteDescription(new RTCSessionDescription(signal.sdp))
        .then(function () {
          // Only create answers in response to offers
          if (signal.sdp.type === 'offer') {
            peerConnection.createAnswer().then(createdDescription).catch(errorHandler)
          }
        })
        .catch(errorHandler)
    } else if (signal.ice) {
      peerConnection.addIceCandidate(new RTCIceCandidate(signal.ice)).catch(errorHandler)
    }
  }

  function gotIceCandidate(event) {
    if (serverConnection.readyState === 1 && event.candidate != null) {
      serverConnection.send(JSON.stringify({ ice: event.candidate, uuid: uuid, room: connect_code }))
    }
  }

  function createdDescription(description) {
    console.log('got description')

    peerConnection
      .setLocalDescription(description)
      .then(function () {
        serverConnection.send(
          JSON.stringify({
            sdp: peerConnection.localDescription,
            uuid: uuid,
            room: connect_code,
          })
        )
      })
      .catch(errorHandler)
  }

  function gotRemoteStream(event) {
    console.log('got remote stream')
    remoteVideo.srcObject = event.streams[0]
    remoteVideoBg.srcObject = event.streams[0]
    setRemoteVideoType(REMOTE_VIDEO_TYPES.WEBCAM)
    setInterval(() => {
      getRemoteStats()
    }, 1200)
  }

  const getRemoteStats = () => {
    peerConnection.getStats(null).then(results => {
      results.forEach(report => {
        const now = report.timestamp
        //console.log(report)
        let bitrate
        if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
          const bytes = report.bytesReceived
          if (timestampPrev) {
            bitrate = (8 * (bytes - bytesPrev)) / (now - timestampPrev)
            bitrate = Math.floor(bitrate)
          }
          bytesPrev = bytes
          timestampPrev = now
          if (!isNaN(bitrate)) {
            setBitrate(bitrate)
          }
        }
      })
    })
  }

  function errorHandler(error) {
    console.log(error)
  }

  useEffect(() => {
    pageReady()
    let interval = setInterval(() => {
      if (localStream) {
        //console.log(localStream)
        join(connect_code)
        start(true)
        clearInterval(interval)
      }
    }, 250)

    return () => {
      closeConnection()
      localStream = undefined
      serverConnection = undefined

      console.log('closed connection')
    }
  }, [])

  const switchMicSound = () => {
    localStream.getAudioTracks()[0].enabled = !micSound
    setMicSound(!micSound)
  }

  const switchRemoteVideoType = () => {
    switch (remoteVideoType) {
      case REMOTE_VIDEO_TYPES.WEBCAM:
        setRemoteVideoType(REMOTE_VIDEO_TYPES.SCREEN)
        break

      case REMOTE_VIDEO_TYPES.SCREEN:
        setRemoteVideoType(REMOTE_VIDEO_TYPES.WEBCAM)
        break

      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <video id='localVideo' autoPlay muted className={styles.own} />
      <div
        onClick={switchRemoteVideoType}
        className={classNames(
          {
            [styles.remoteVideo_active]: remoteVideoType === REMOTE_VIDEO_TYPES.WEBCAM,
            'd-none': remoteVideoType === REMOTE_VIDEO_TYPES.NONE,
          },
          styles.remoteVideo_container
        )}
      >
        <video id='remoteVideo' autoPlay className={styles.interlocutor} />
        <video id='remoteVideoBg' autoPlay className={styles.videoBg} />
      </div>
      <div
        onClick={switchRemoteVideoType}
        className={classNames(
          {
            'd-none': !remoteScreenRecording,
            [styles.remoteVideo_active]: remoteVideoType === REMOTE_VIDEO_TYPES.SCREEN,
          },
          styles.remoteVideo_container
        )}
      >
        <video id='remoteScreen' autoPlay />
      </div>

      {completionScreen && <StatePlug text={'Кандидат покинул собеседование'} />}

      <div className={styles.videoControls}>
        <button onClick={requestScreenSharing} className={styles.controls_button} title={`демонстрация экрана`}>
          <img src={screen_share} />
        </button>
        <button
          onClick={switchMicSound}
          className={styles.controls_button}
          title={`${micSound ? 'Выключить' : 'Включить'} микрофон`}
        >
          <img src={micSound ? mic_active : mic_mute} />
        </button>
      </div>
      <div className={styles.videoLayer}>Битрейт: {bitrate} Kbps</div>
    </div>
  )
}

WebRtc.propTypes = {
  connect_code: PropTypes.string.isRequired,
  requestApplicantAnswer: PropTypes.func,
}

export default WebRtc
